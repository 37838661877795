import React, { useEffect } from 'react';
import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import Main from "./Components/Main";
import Experience from "./Components/Experience";
import Projects from "./Components/Projects";
import Space from "./Components/Space";
import Space2 from "./Components/Space2";
import Button from "./Components/Button";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Main />
      <Space />
      <Experience />
      <Space2 />
      <Projects />
      <Button />
    </div>
  );
}

export default App;
