import React, { Component } from 'react'
import './Experience.css'
 
class Experience extends Component {
    state = { 
        active: true,
        check: true,
        check2: true,
        check3: true,
        check4: true,
    }
    onActive = () => {
        this.setState({ active: !this.state.active })
    };
    onCheck = () => {
        this.setState({ check: !this.state.check })
        var res = document.querySelectorAll("#data2,#data3,#data4");
        var res2 = document.querySelectorAll("#info2,#info3,#info4");
        if (this.state.check === true) {
            document.querySelector(".qualification__info").style.display = "block";
            for (var i = 0; i < res.length; i++) {
                res[i].style.filter = "blur(2px)";
            }
            for (var j = 0; j < res2.length; j++) {
                res2[j].style.display = "none";
            }
        } else {
            document.querySelector(".qualification__info").style.display = "none";
            for (var k = 0; k < res.length; k++) {
                res[k].style.filter = "blur(0)";
            }
        }
    };
    onCheck2 = () => {
        this.setState({ check2: !this.state.check2 })
        var res = document.querySelectorAll("#data1,#data3,#data4");
        var res2 = document.querySelectorAll("#info1,#info3,#info4");
        if (this.state.check2 === true) {
            document.querySelector("#info2").style.display = "block";
            for (var i = 0; i < res.length; i++) {
                res[i].style.filter = "blur(2px)";
            }
            for (var j = 0; j < res2.length; j++) {
                res2[j].style.display = "none";
            }
        } else {
            document.querySelector("#info2").style.display = "none";
            for (var k = 0; k < res.length; k++) {
                res[k].style.filter = "blur(0)";
            }
        }
    };
    onCheck3 = () => {
        this.setState({ check3: !this.state.check3 })
        var res = document.querySelectorAll("#data1,#data2,#data4");
        var res2 = document.querySelectorAll("#info1,#info2,#info4");
        if (this.state.check3 === true) {
            document.querySelector("#info3").style.display = "block";
            for (var i = 0; i < res.length; i++) {
                res[i].style.filter = "blur(2px)";
            }
            for (var j = 0; j < res2.length; j++) {
                res2[j].style.display = "none";
            }
        } else {
            document.querySelector("#info3").style.display = "none";
            for (var k = 0; k < res.length; k++) {
                res[k].style.filter = "blur(0)";
            }
        }
    };
    onCheck4 = () => {
        this.setState({ check4: !this.state.check4 })
        var res = document.querySelectorAll("#data1,#data2,#data3");
        var res2 = document.querySelectorAll("#info1,#info2,#info3");

        if (this.state.check4 === true) {
            document.querySelector("#info4").style.display = "block";
            for (var i = 0; i < res.length; i++) {
                res[i].style.filter = "blur(2px)";
            } 
            for (var j = 0; j < res2.length; j++) {
                res2[j].style.display = "none";
            }       
        } else {
            document.querySelector("#info4").style.display = "none";
            for (var k = 0; k < res.length; k++) {
                res[k].style.filter = "blur(0)";
            }        
        }
    };

    render() {
        return (
            <div className='qualbox'>
                    <section className='qualification section'>
                    <h2 className='subheading' id='exp'>My Journey</h2>
                    <div className='qualification__container container'>
                        <div className='qualification__tabs'>
                            <div className={this.state.active ? 'qualification__button qualification__active button--flex' : 'qualification__button button--flex'} 
                                onClick= {() => {                           
                                    this.onActive()  
                                }}>
                                <i className='fa-solid fa-graduation-cap qualification__icon'></i>Experience
                            </div>
                            <div className={this.state.active ? 'qualification__button button--flex' : 'qualification__button qualification__active button--flex'}
                                onClick= {this.onActive}>
                                <i className='fa-solid fa-briefcase qualification__icon'></i>Education
                            </div>
                        </div>
                        <div className='qualification__sections'>
                            <div className={this.state.active ? 'qualification__content qualification__content-active' : 'qualification__content'}>
                                <div className='qualification__data' id='data1' onClick={this.onCheck}>
                                    <div className='qualification__back'>
                                        <h3 className='qualification__title'>Software Engineer</h3>
                                        <span className='qualification__subtitle'>Avolta</span>
                                        <div className='qualification__calender'>
                                            <i className='fa-solid fa-calendar'></i> May 2022 - Nov 2022
                                        </div>
                                    </div>
                                    <div>
                                        <span className='qualification__rounder'></span>
                                        <span className='qualification__line'></span>
                                    </div>
                                    
                                </div>
                                <div className='qualification__info' id="info1">
                                    <ul>
                                        <li>Worked on major refactoring efforts, re-architecting the front-end using <b>ReactJS</b> and implementing a <b>one-way data binding design</b>, resulting in improved code maintainability and scalability</li>
                                        <li>Implemented a <b>user login authentication system</b> using services provided by <b>Google Firebase</b></li>
                                        <li>Participated in code reviews, design reviews, and pair programming to improve software quality and maintain best coding practices</li>
                                    </ul>
                                </div>
                                <div className='qualification__data' id='data2' onClick={this.onCheck2}>
                                    <div></div>
                                    <div>
                                        <span className='qualification__rounder'></span>
                                        <span className='qualification__line'></span>
                                    </div>
                                    <div className='qualification__back2'>
                                        <h3 className='qualification__title'>Software Engineer Intern</h3>
                                        <span className='qualification__subtitle'>GAOTek </span>
                                        <div className='qualification__calender'>
                                            <i className='fa-solid fa-calendar'></i> Jan 2022 - April 2022
                                        </div>
                                    </div>
                                </div>
                                <div className='qualification__info' id="info2">
                                    <ul>
                                        <li>Queried, parsed and analyzed JSON data from <b>Apple's iBeacon</b> and utilized <b>HTTP / MQTT/ TCP</b> protocol to transmit data to remote servers</li>
                                        <li> Worked on an Android application using <b>Kotlin</b>, utilizing various libraries and frameworks such as <b>Retrofit</b> and <b>Dagger</b> to handle responses and improve code testability, resulting in efficient and robust software </li>
                                        <li>Lead a team of <b>15+</b> technical writing interns. Published comprehensive product specs and implementations on the company website. Increased company recognition and page clicks by over <b>85%</b></li>
                                    </ul>
                                </div>
                                <div className='qualification__data' id='data3' onClick={this.onCheck3}>
                                    <div className='qualification__back'>
                                        <h3 className='qualification__title'>Full Stack Web Developer</h3>
                                        <span className='qualification__subtitle'>OnTop Property Services</span>
                                        <div className='qualification__calender'>
                                            <i className='fa-solid fa-calendar'></i> Aug 2021 - Aug 2021
                                        </div>
                                    </div>
                                    <div>
                                        <span className='qualification__rounder'></span>
                                        <span className='qualification__line'></span>
                                    </div>
                                </div>
                                <div className='qualification__info' id="info3">
                                    <ul>
                                        <li>Developed a <b>React</b> web application for a start-up, with a focus on responsive and user-friendly interfaces</li>
                                        <li>Implemented a variety of database technologies, including <b>MySQL</b>, <b>MongoDB</b>, and <b>Firebase</b>, to manage data storage and keep track of current and potential clients</li>
                                    </ul>
                                </div>
                                <div className='qualification__data' id='data4' onClick={this.onCheck4}>
                                    <div></div>
                                    <div>
                                        <span className='qualification__rounder'></span>
                                        <span className='qualification__line'></span>
                                    </div>
                                    <div className='qualification__back2'>
                                        <h3 className='qualification__title'>Software Development Team Lead</h3>
                                        <span className='qualification__subtitle'>First Robotics </span>
                                        <div className='qualification__calender'>
                                            <i className='fa-solid fa-calendar'></i> Oct 2020 - April 2021
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='qualification__info' id="info4">
                                    <ul>
                                        <li>Led a software development team in the design, development and maintenance of several projects invovling <b>AutoCAD</b> and <b>Python</b></li>
                                        <li>Managed and mentored junior team members, fostering a collaborative and productive work environment </li>
                                        <li>Implemented Agile development methodologies, ensuring efficient and timely delivery of projects</li>
                                    </ul>
                            </div>       
                            <div className={this.state.active ? 'qualification__content' : 'qualification__content qualification__content-active'}>
                                <div className='qualification__data'>
                                    <div>
                                        <h3 className='qualification__title'>Computer Science</h3>
                                        <span className='qualification__subtitle'>University of Waterloo</span>
                                        <div className='qualification__calender'>
                                            <i className='fa-solid fa-calendar'></i> Sept 2021 - Present
                                        </div>
                                    </div>
                                    <div>
                                        <span className='qualification__rounder'></span>
                                        <span className='qualification__line'></span>
                                    </div>
                                </div>
                                <div className='qualification__data'>
                                <div></div>
                                    <div>
                                        <span className='qualification__rounder'></span>
                                        <span className='qualification__line'></span>
                                    </div>
                                    <div>
                                        <h3 className='qualification__title'>Business Administration</h3>
                                        <span className='qualification__subtitle'>Wilfrid Laurier</span>
                                        <div className='qualification__calender'>
                                            <i className='fa-solid fa-calendar'></i> Sept 2021 - April 2022
                                        </div>
                                    </div>
                                </div>        
                            </div>   
                        </div>
                    </div>
                </section>
            </div>
            
        )
    }
}

export default Experience
