import React, { Component } from 'react'
import './Button.css'

class Button extends Component {
    render() {
        return (
            <a class="gotopbtn" href="#home">
                <i class="fas fa-angle-double-up"></i>
            </a>
        )
    }
}

export default Button