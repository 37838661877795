import React, { Component } from 'react'
import { MenuItems } from './Menuitems'
import './Navbar.css'
import linkedin from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/link.png'
import github from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/github.png'
import resume from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/resume.png'
import res from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/Resume.pdf'

class Navbar extends Component {
    state = { active: false}
    onActive = () => {
        this.setState({ active: !this.state.active })
    };
    
    render() {
        return (
            <nav className='NavbarI' id='home'>
                <div className='navbar-logo'>
                    <i className='fa-solid fa-section'></i>
                </div>
                <div className='menu-icon' onClick= {this.onActive}>
                    <i className={this.state.active ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={this.state.active ? 'nav-menu active' : 'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <a className= "links" href={item.url} onClick = {() => {
                                     document.querySelector(".qualification").style.display = "block";
                                     document.querySelector(".projects").style.display = "flex";
                                     document.querySelector(".gotopbtn").style.display = "block";
                                     this.onActive()
                                }} >
                                {item.title}
                                </a>
                            </li>  
                        )
                    })}
                </ul>
                <span className="navbar-text">
                    <div className="social-icon">
                        <a href="https://www.linkedin.com/in/sajeenth-shiva-9466b119a/" target="_blank" rel="noreferrer"><img src={linkedin} alt="" /></a>
                        <a href="https://github.com/sajeenth" target="_blank" rel="noreferrer"><img src={github} alt="" /></a>
                        <a href={res} target="_blank" rel="noreferrer"><img src={resume} alt="" /></a>
                    </div>
                </span>
            </nav>
        )
    }
}
export default Navbar
