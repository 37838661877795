export const MenuItems = [
    {
        title: 'Home',
        url: '#home',
        uname: 'links',
    },
    {
        title: 'Experience',
        url: '#exp',
        uname: 'links',
    },
    {
        title: 'Projects',
        url: '#empty2',
        uname: 'links',
    },
]
