import React, { Component } from 'react'
import './Main.css'
import icon from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/3.png'
 
class Main extends Component {
    state = { active: false}
    onActive = () => {
        this.setState({ active: !this.state.active })
        document.querySelector(".qualification").style.display = "block";
        document.querySelector(".projects").style.display = "flex";
        document.querySelector(".gotopbtn").style.display = "block";
        window.scrollTo(0,730)
    };

    render() {
        return (
            <div className='home'>
                <div className='bg'> 
                    <div className='title'>
                        <span className='title-item'>H</span>
                        <span className='title-item'>i</span>
                        <span className='title-item' style = {{marginRight: '.8rem'}}>!</span>
                        <span className='title-item'>I</span>
                        <span className='title-item'>'</span>
                        <span className='title-item' style = {{marginRight: '.8rem'}}>m</span>
                        <span className='title-item'>S</span>
                        <span className='title-item'>a</span>
                        <span className='title-item'>j</span>
                        <span className='title-item'>e</span>
                        <span className='title-item'>e</span>
                        <span className='title-item'>n</span>
                        <span className='title-item'>t</span>
                        <span className='title-item'>h</span>
                    </div>
                    <div className='wrapper'> 
                        <p>I'm a</p>
                        <div className='words'>
                            <span>developer</span>
                            <span>programmer</span>
                            <span>student</span>
                            <span>traveller</span>
                            <span>innovater</span>
                        </div>
                    </div>
                    <div className='view'>
                        <button onClick= {this.onActive}>View More <i class="fa-solid fa-arrow-down"></i></button>
                    </div>
                    <div className='image'>
                        <img src={icon} alt="" id='pp'></img>
                    </div>
                </div>
            </div>
        )
    }
}
export default Main
