import React, { Component } from 'react'
import './Space.css'

class Space extends Component {
    render() {
        return (
            <div classname="space" id="empty">
                &nbsp;
            </div>
        )
    }
}

export default Space