import React, { Component } from 'react'
import './Space.css'

class Space2 extends Component {
    render() {
        return (
            <div classame="space2" id="empty2">
                &nbsp;
            </div>
        )
    }
}

export default Space2