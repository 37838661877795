import React, { Component } from 'react'
import './Projects.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import typing from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/typing.png'
import dd from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/dd.png'
import country from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/country.gif'
import nasa from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/nasa.jpg'
import e_com from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/e_com.jpg'
import crypto from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/crypto.PNG'
import circle from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/circle.PNG'
import crp from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/crp.mp4'
import circle_vid from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/circle_vid.mp4'
import dd_vid from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/dd_vid.mp4'
import nasa_vid from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/nasa_vid.mp4'
import typ_vid from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/typ_vid.mp4'
import country_vid from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/country_vid.mp4'
import e_com_vid from 'H:/OneDrive/Desktop/Projects/Portfolio/portfolio/src/other/e_com_vid.mp4'
import 'swiper/css'


class Projects extends Component {
    render() {
        return (
            <div className='projects'>
                <h2 className='subheading' id="pro">Recent Projects</h2>
                <div class="popup" id="popup">
                    <div class="box"></div>
                    <div class="conbox">
                        <div class="content">
                            <h3>CryptoBase</h3>
                            <div class="close" onClick={() => {
                                document.querySelector(".popup").style.display = "none";
                                document.querySelector("*").style.overflow = "visible";
                            }}>&times;</div>
                            <div class="next" onClick={() => {
                                document.querySelector(".content").style.display = "none";
                                document.querySelector(".content2").style.display = "block";
                            }}> <i className='fa-solid fa-arrow-right'></i></div>
                            <video src={crp} controls="controls" autoPlay="True" ></video>
                            <div className='lang' id="p_instruct">
                                <h5>React</h5>
                                <h5>JavaScript</h5>
                                <h5>Firebase</h5>
                            </div>
                            <p>React web application that provides users with up to date market insights (Price, Volume, Market Cap) on over 100+ cryptocurrencies</p>
                            <div class="project-btn-grp">
                                <button onClick= {() => {
                                    window.open('https://github.com/sajeenth/CryptoBase')
                                }} class="project-btn ">Github Repo</button>
                                <button onClick= {() => {
                                    window.open('https://cryptobase-47408.web.app/','blank')
                                }} class="project-btn ">Check it Out!</button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="content2">
                        <h3>Double-Degree-BookMap</h3>
                        <div class="close" onClick={() => {
                            document.querySelector(".popup").style.display = "none";
                            document.querySelector("*").style.overflow = "visible";
                        }}>&times;</div>
                        <div class="prev" onClick={() => {
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-left'></i></div>
                        <div class="next" onClick={() => {
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-right'></i></div>
                        <video src={dd_vid} controls="controls" autoPlay="True" ></video>
                        <div className='lang' id="p_instruct">
                            <h5>HTML</h5>
                            <h5>JavaScript</h5>
                            <h5>CSS</h5>
                        </div>
                        <p>Google Chrome extension that helps double-degree students with accessing their course websites</p>
                        <div class="project-btn-grp">
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/Double-Degree-BookMap')
                            }} class="project-btn ">Github Repo</button>
                            <button onClick= {() => {
                                window.open('https://chrome.google.com/webstore/category/extensions','blank')
                            }} class="project-btn ">Check it Out!</button>
                        </div>
                    </div>
                    <div class="content3">
                        <h3>CountryLearner</h3>
                        <div class="close" onClick={() => {
                            document.querySelector(".popup").style.display = "none";
                            document.querySelector("*").style.overflow = "visible";
                        }}>&times;</div>
                        <div class="prev" onClick={() => {
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content2").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-left'></i></div>
                        <div class="next" onClick={() => {
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-right'></i></div>
                        <video src={country_vid} controls="controls" autoPlay="True" ></video>
                        <div className='lang' id="p_instruct">
                            <h5>Django</h5>
                            <h5>Python Requests</h5>
                            <h5>BeautifulSoup</h5>
                            <h5>HTML</h5>
                            <h5>CSS</h5>
                        </div>
                        <p>A web application that scrapes and retrieves country data from TradingEconomics and produces statistics of a given country</p>
                        <div class="project-btn-grp">
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/CountryLearner')
                            }} class="project-btn ">Github Repo</button>
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/CountryLearner','blank')
                            }} class="project-btn ">Check it Out!</button>
                        </div>
                    </div>
                    <div class="content4">
                        <h3>E-Commerce Database</h3>
                        <div class="close" onClick={() => {
                            document.querySelector(".popup").style.display = "none";
                            document.querySelector("*").style.overflow = "visible";
                        }}>&times;</div>
                        <div class="prev" onClick={() => {
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content3").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-left'></i></div>
                        <div class="next" onClick={() => {
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-right'></i></div>
                        <video src={e_com_vid} controls="controls" autoPlay="True" ></video>
                        <div className='lang' id="p_instruct">
                            <h5>React</h5>
                            <h5>NodeJS</h5>
                            <h5>MySQL</h5>
                        </div>
                        <p>A CRUD application that models an E-commerce database and provides both customer and admin permissions </p>
                        <div class="project-btn-grp">
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/E-Commerce-Database')
                            }} class="project-btn ">Github Repo</button>
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/E-Commerce-Database','blank')
                            }} class="project-btn ">Check it Out!</button>
                        </div>
                    </div>
                    <div class="content5">
                        <h3>WordBeater</h3>
                        <div class="close" onClick={() => {
                            document.querySelector(".popup").style.display = "none";
                            document.querySelector("*").style.overflow = "visible";
                        }}>&times;</div>
                        <div class="prev" onClick={() => {
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content4").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-left'></i></div>
                        <div class="next" onClick={() => {
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-right'></i></div>
                        <video src={typ_vid} controls="controls" autoPlay="True" ></video>
                        <div className='lang' id="p_instruct">
                            <h5>HTML</h5>
                            <h5>JavaScript</h5>
                            <h5>CSS</h5>
                        </div>
                        <p>An interactive tool to help users practise their typing skills</p>
                        <div class="project-btn-grp">
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/WordBeater')
                            }} class="project-btn ">Github Repo</button>
                            <button onClick= {() => {
                                window.open('https://wordbeater-9fd3c.web.app/','blank')
                            }} class="project-btn ">Check it Out!</button>
                        </div>
                    </div>
                    <div class="content6">
                        <h3>Daily Astrophotography</h3>
                        <div class="close" onClick={() => {
                            document.querySelector(".popup").style.display = "none";
                            document.querySelector("*").style.overflow = "visible";
                        }}>&times;</div>
                        <div class="prev" onClick={() => {
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content5").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-left'></i></div>
                        <div class="next" onClick={() => {
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "block";
                        }}> <i className='fa-solid fa-arrow-right'></i></div>
                        <video src={nasa_vid} controls="controls" autoPlay="True" ></video>
                        <div className='lang' id="p_instruct">
                            <h5>HTML</h5>
                            <h5>JavaScript</h5>
                            <h5>CSS</h5>
                        </div>
                        <p>A web application that produces an astrophotographic photo from Nasa resources using NASA's APOD API.</p>
                        <div class="project-btn-grp">
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/Daily-Astrophotography')
                            }} class="project-btn ">Github Repo</button>
                            <button onClick= {() => {
                                window.open('https://github.com/sajeenth/Daily-Astrophotography','blank')
                            }} class="project-btn ">Check it Out!</button>
                        </div>
                    </div>
                    <div class="content7">
                        <h3>CircleSpotter</h3>
                        <div class="close" onClick={() => {
                                document.querySelector(".popup").style.display = "none";
                                document.querySelector("*").style.overflow = "visible";
                            }}>&times;</div>
                            <div class="prev" onClick={() => {
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector(".content6").style.display = "block";
                            }}> <i className='fa-solid fa-arrow-left'></i></div>
                            <video src={circle_vid} controls="controls" autoPlay="True" ></video>
                            <div className='lang' id="p_instruct">
                                <h5>HTML</h5>
                                <h5>JavaScript</h5>
                                <h5>CSS</h5>
                            </div>
                            <p>A fun and interactive web game where the user has to locate the invisibile circle using riddles and their critical thinking skills</p>
                            <div class="project-btn-grp">
                                <button onClick= {() => {
                                    window.open('https://github.com/sajeenth/CircleSpotter')
                                }} class="project-btn ">Github Repo</button>
                                <button onClick= {() => {
                                    window.open('https://circlespotter.web.app/','blank')
                                }} class="project-btn ">Check it Out!</button>
                        </div>
                    </div>
                </div>
                <Swiper className='projects-slider' grabCursor={true} breakpoints= {{
                    1920: {
                        spaceBetween: 80,
                        slidesPerView: 3
                    },
                    950: {
                        spaceBetween: 30,
                        slidesPerView: 3
                    },
                    650: {
                        spaceBetween: 10,
                        slidesPerView: 2
                    }
                }} >
                    <SwiperSlide>
                        <img src={crypto} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "block";
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector("*").style.overflow = "hidden";
                            if (window.innerWidth < 500) {
                                window.scrollTo(0,1870)
                            } else {
                                window.scrollTo(0,1970)
                            }
                        }}></img>
                    </SwiperSlide>
                    <SwiperSlide className='pro2'>
                        <img src={dd} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "none";
                            document.querySelector(".content2").style.display = "block";
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector("*").style.overflow = "hidden";
                            window.scrollTo(0,1970)
                        }}></img>
                    </SwiperSlide>
                    <SwiperSlide className='pro3'>
                        <img src={country} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "none";
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "block";
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector("*").style.overflow = "hidden";
                            window.scrollTo(0,1970)
                        }}></img>
                    </SwiperSlide>
                    <SwiperSlide className='pro4'>
                        <img src={e_com} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "none";
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "block";
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector("*").style.overflow = "hidden";
                            window.scrollTo(0,1970)
                        }}></img>
                    </SwiperSlide>
                    <SwiperSlide className='pro5'>
                        <img src={typing} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "none";
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "block";
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector("*").style.overflow = "hidden";
                            window.scrollTo(0,1970)
                        }}></img>
                    </SwiperSlide>
                    <SwiperSlide className='pro6'> 
                        <img src={nasa} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "none";
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "block";
                            document.querySelector(".content7").style.display = "none";
                            document.querySelector("*").style.overflow = "hidden";
                            window.scrollTo(0,1970)
                        }}></img>
                    </SwiperSlide>
                    <SwiperSlide className='pro7'>
                        <img src={circle} width="425px" height="350px" alt= "" onClick={() => {
                            document.querySelector(".popup").style.display = "block";
                            document.querySelector(".content").style.display = "none";
                            document.querySelector(".content2").style.display = "none";
                            document.querySelector(".content3").style.display = "none";
                            document.querySelector(".content4").style.display = "none";
                            document.querySelector(".content5").style.display = "none";
                            document.querySelector(".content6").style.display = "none";
                            document.querySelector(".content7").style.display = "block";
                            document.querySelector("*").style.overflow = "hidden";
                            window.scrollTo(0,1970)
                        }}></img>
                    </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}

export default Projects
